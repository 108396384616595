<template>
    <div style="width: 100%;" v-loading="loading">
        <div style="width: 96%; margin-left:2%; height:290px; border: 1px solid #ddd; margin-top: 20px;">
            <p style="border-left: 4px solid blue; line-height: 20px; margin: 20px; padding-left: 10px;">商户基本信息</p>
            <div>
                <table style="width: 100%; text-align: center; margin-top: 8px; line-height: 55px;">
                    <tr>
                        <th>登录名：{{total.value1}}</th>
                        <th>绑定邮箱：{{total.value2}} <button @click="updateBuzon()">修改</button></th>
                    </tr>
                    <tr>
                        <th>商户名称：{{total.value1}}</th>
                        <th>商户id：{{total.value3}}</th>
                    </tr>
                    <tr>
                        <th style="width: 200px;" :title='total.value9'>
                            商户秘钥：{{total.value9}}
                        </th>
                    </tr>
                </table>
            </div>
        </div>
        
        <div style="width: 96%; margin-left:2%; height:270px; border: 1px solid #ddd; margin-top: 20px;">
            <p style="border-left: 4px solid blue; line-height: 20px; margin: 20px; padding-left: 10px;">应用信息</p>
            <div>
                <table style="width: 100%; margin-top: -10px; line-height: 38px;">
                    <el-row :gutter="12" style="margin-left: 10px;">
                        <el-col v-if="total.value4 != null" :span="6">
                            <el-card shadow="always">
                                <tr>
                                    <th>应用名称: {{total.value4}}
                                        <button>使用中</button>
                                    </th>
                                </tr>
                                <tr>
                                    <th>应用ID: {{total.value5}}</th>
                                </tr>
                                <tr>
                                    <th>关联账户: {{total.value6}}</th>
                                </tr>
                                <tr>
                                    <th>创建时间: {{total.value7}}</th>
                                </tr>
                            </el-card>
                        </el-col>
                        <el-col v-if="total.value10 != null" :span="6">
                            <el-card shadow="always">
                                <tr>
                                    <th>应用名称: {{total.value10}}
                                        <button>使用中</button>
                                    </th>
                                </tr>
                                <tr>
                                    <th>应用ID: {{total.value11}}</th>
                                </tr>
                                <tr>
                                    <th>关联账户: {{total.value12}}</th>
                                </tr>
                                <tr>
                                    <th>创建时间: {{total.value13}}</th>
                                </tr>
                            </el-card>
                        </el-col>

                        <el-col v-if="total.value15 != null" :span="6">
                            <el-card shadow="always">
                                <tr>
                                    <th>应用名称: {{total.value15}}
                                        <button>使用中</button>
                                    </th>
                                </tr>
                                <tr>
                                    <th>应用ID: {{total.value16}}</th>
                                </tr>
                                <tr>
                                    <th>关联账户: {{total.value17}}</th>
                                </tr>
                                <tr>
                                    <th>创建时间: {{total.value18}}</th>
                                </tr>
                            </el-card>
                        </el-col>

                        <el-col v-if="total.value20 != null" :span="6">
                            <el-card shadow="always">
                                <tr>
                                    <th>应用名称: {{total.value20}}
                                        <button>使用中</button>
                                    </th>
                                </tr>
                                <tr>
                                    <th>应用ID: {{total.value21}}</th>
                                </tr>
                                <tr>
                                    <th>关联账户: {{total.value22}}</th>
                                </tr>
                                <tr>
                                    <th>创建时间: {{total.value23}}</th>
                                </tr>
                            </el-card>
                        </el-col>

                        <el-col v-if="total.value25 != null" :span="6">
                            <el-card shadow="always">
                                <tr>
                                    <th>应用名称: {{total.value25}}
                                        <button>使用中</button>
                                    </th>
                                </tr>
                                <tr>
                                    <th>应用ID: {{total.value26}}</th>
                                </tr>
                                <tr>
                                    <th>关联账户: {{total.value27}}</th>
                                </tr>
                                <tr>
                                    <th>创建时间: {{total.value28}}</th>
                                </tr>
                            </el-card>
                        </el-col>

                        <el-col v-if="total.value30 != null" :span="6">
                            <el-card shadow="always">
                                <tr>
                                    <th>应用名称: {{total.value30}}
                                        <button>使用中</button>
                                    </th>
                                </tr>
                                <tr>
                                    <th>应用ID: {{total.value31}}</th>
                                </tr>
                                <tr>
                                    <th>关联账户: {{total.value32}}</th>
                                </tr>
                                <tr>
                                    <th>创建时间: {{total.value33}}</th>
                                </tr>
                            </el-card>
                        </el-col>
                    </el-row>
                </table>
            </div>
        </div>
        <div style="width: 96%; margin-left:2%; height:270px; border: 1px solid #ddd; margin-top: 20px;">
            <p style="border-left: 4px solid blue; line-height: 20px; margin: 20px;  padding-left: 10px;">开通服务</p>
            <div>
                <table style="width: 100%; margin-top: -10px; line-height: 52px;">
                    <el-row :gutter="12" style="margin-left: 10px;">
                        <el-col v-if="total.value8 != null" :span="6">
                            <el-card shadow="hover">
                                <tr>
                                    <th>{{total.value8}}</th>
                                </tr>
                                <tr>
                                    <th>stp-pay代收</th>
                                </tr>
                                <tr>
                                    <th>stp-pay代付</th>
                                </tr>
                            </el-card>
                        </el-col>
                        <el-col v-if="total.value14 != null" :span="6">
                            <el-card shadow="hover">
                                <tr>
                                    <th>{{total.value14}}</th>
                                </tr>
                                <tr>
                                    <th>oxxo-pay代收</th>
                                </tr>
                            </el-card>
                        </el-col>
                        <el-col v-if="total.value19 != null" :span="6">
                            <el-card shadow="hover">
                                <tr>
                                    <th>{{total.value19}}</th>
                                </tr>
                                <tr>
                                    <th>cardPay代收</th>
                                </tr>
                            </el-card>
                        </el-col>
                        <el-col v-if="total.value24 != null" :span="6">
                            <el-card shadow="hover">
                                <tr>
                                    <th>{{total.value24}}</th>
                                </tr>
                                <tr>
                                    <th>paynet代收</th>
                                </tr>
                            </el-card>
                        </el-col>
                        <el-col v-if="total.value29 != null" :span="6">
                            <el-card shadow="hover">
                                <tr>
                                    <th>{{total.value29}}</th>
                                </tr>
                                <tr>
                                    <th>toppay代收</th>
                                </tr>
                                <tr>
                                    <th>toppay代付</th>
                                </tr>
                            </el-card>
                        </el-col>

                        <el-col v-if="total.value34 != null" :span="6">
                            <el-card shadow="hover">
                                <tr>
                                    <th>{{total.value34}}</th>
                                </tr>
                                <tr>
                                    <th>opm代收</th>
                                </tr>
                                <tr>
                                    <th>opm代付</th>
                                </tr>
                            </el-card>
                        </el-col>
                    </el-row>
                </table>
            </div>
        </div>
        <pop-up title="修改邮箱" :isshow="showadd" :haveconfirm="true" @close="getclose" @confirm="getconfirm">
            <div slot="box">
                <el-form ref="form" :inline="true" :model="form" :rules="rules" size="small">
                    <el-form-item label="邮箱" prop="buzon">
                        <el-input v-model="form.buzon" />
                    </el-form-item>
                </el-form>
            </div>
        </pop-up>
    </div>
</template>
<script>
import apiurl from "@/api/public"
import _api from "@/api/index"
export default {
    data(){
        return{
            loading: false,
            showadd: false,
            form: {
                buzon: null,
            },
            total:{
                value1: 0,
                value2: 0,
                value3: 0,
                value4: 0,
                value5: 0,
                value6: 0,
                value7: 0,
                value8: 0,
                value9: 0,
                value10: 0,
                value11: 0,
                value12: 0,
                value13: 0,
                value14: 0,

                value15: 0,
                value16: 0,
                value17: 0,
                value18: 0,
                value19: 0,

                value20: 0,
                value21: 0,
                value22: 0,
                value23: 0,
                value24: 0,

                value25: 0,
                value26: 0,
                value27: 0,
                value28: 0,
                value29: 0,

                value30: 0,
                value31: 0,
                value32: 0,
                value33: 0,
                value34: 0,
            },
        }
    },
    created(){
        this.getTotal();
    },
    methods:{
        getTotal(){
            this.loading = true
            _api.get(apiurl.essential,{
            }).then(res=>{
                this.loading = false
                this.total.value1=res.data.value1
                this.total.value2=res.data.value2
                this.total.value3=res.data.value3
                this.total.value4=res.data.value4
                this.total.value5=res.data.value5
                this.total.value6=res.data.value6
                this.total.value7=res.data.value7
                this.total.value8=res.data.value8
                this.total.value9=res.data.value9
                this.total.value10=res.data.value10
                this.total.value11=res.data.value11
                this.total.value12=res.data.value12
                this.total.value13=res.data.value13
                this.total.value14=res.data.value14
                this.total.value15=res.data.value15
                this.total.value16=res.data.value16
                this.total.value17=res.data.value17
                this.total.value18=res.data.value18
                this.total.value19=res.data.value19

                this.total.value20=res.data.value20
                this.total.value21=res.data.value21
                this.total.value22=res.data.value22
                this.total.value23=res.data.value23
                this.total.value24=res.data.value24

                this.total.value25=res.data.value25
                this.total.value26=res.data.value26
                this.total.value27=res.data.value27
                this.total.value28=res.data.value28
                this.total.value29=res.data.value29

                this.total.value30=res.data.value30
                this.total.value31=res.data.value31
                this.total.value32=res.data.value32
                this.total.value33=res.data.value33
                this.total.value34=res.data.value34
            })
        },

        updateBuzon() {
            this.showadd = true
        },

        getconfirm() {
            if(this.form.buzon == null || this.form.buzon == ""){
                this.$message.error('邮箱不能不空')
                return
            }
            _api.put(apiurl.updateBuzon, this.form)
            .then(res => {
                this.$notify.success({
                    title: this.$t('success'),
                    message: '修改成功'
                })
                this.getTotal(),
                this.form = {
                    buzon: null,
                }
                this.showadd = false
            }).catch(err => {
                this.$notify.error({
                    title: this.$t('error'),
                    message: err.data.message
                })
            })
        },
        getclose() {
            this.$refs.form.resetFields();
            this.showadd = false
        },

        copyLink(){
            let clipboard = new Clipboard('.fuzhi-btn');
                clipboard.on('success', ()=> {
                    this.$message({
                        type: "success",
                        message: "复制成功",
                        duration: 1500,
                        showClose: true,
                    });
                    clipboard.destroy();// 使用destroy清除缓存
                });
                clipboard.on('error', ()=> {
                    this.$message({
                        message: 'Copy error',
                        type: 'error',
                        duration: 1500
                    });
                    clipboard.destroy(); // 使用destroy清除缓存
                })
            }
        }
}
</script>
<style scoped>
    th {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 400px;
    }
</style>